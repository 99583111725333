var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rss-wrapper" }, [
    _vm.meta
      ? _c(
          "a",
          {
            staticClass: "meta-container",
            attrs: { href: _vm.meta.link, title: _vm.meta.description },
          },
          [
            _vm.meta.image
              ? _c("img", {
                  staticClass: "feed-icon",
                  attrs: { src: _vm.meta.image, alt: "Feed Image" },
                })
              : _vm._e(),
            _c("div", { staticClass: "feed-text" }, [
              _c("p", { staticClass: "feed-title" }, [
                _vm._v(_vm._s(_vm.meta.title)),
              ]),
              _vm.meta.author
                ? _c("p", { staticClass: "feed-author" }, [
                    _vm._v("By " + _vm._s(_vm.meta.author)),
                  ])
                : _vm._e(),
            ]),
          ]
        )
      : _vm._e(),
    _vm.posts
      ? _c(
          "div",
          { staticClass: "post-wrapper" },
          _vm._l(_vm.posts, function (post, indx) {
            return _c("div", { key: indx, staticClass: "post-row" }, [
              _c("a", { staticClass: "post-top", attrs: { href: post.link } }, [
                post.image
                  ? _c("img", {
                      staticClass: "post-img",
                      attrs: { src: post.image, alt: "Post Image" },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "post-title-wrap" }, [
                  _c("p", { staticClass: "post-title" }, [
                    _vm._v(_vm._s(post.title)),
                  ]),
                  _c("p", { staticClass: "post-date" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("formatDate")(post.date)) +
                        " " +
                        _vm._s(_vm._f("formatAuthor")(post.author)) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("div", {
                staticClass: "post-body",
                domProps: { innerHTML: _vm._s(post.description) },
              }),
              _c(
                "a",
                {
                  staticClass: "continue-reading-btn",
                  attrs: { href: post.link },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("widgets.general.open-link")) + " "
                  ),
                ]
              ),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }